import Appointlet from '@appointlet/appointlet.js';

import GLOBALS from 'app-globals';

export const openAppointletModal = () => {
  const appointlet = new Appointlet(GLOBALS.LINKS.BOOK_DEMO);

  if (appointlet) {
    appointlet.openModal();
  }
};

import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/caveat/700.css';
import '@fontsource/material-icons/index.css';
import '@appointlet/appointlet.js/dist/appointlet.min.css';

import CustomLayout from './wrapPageElement';

import './src/styles/root.scss';

export const wrapPageElement = CustomLayout;

import React from 'react';

import clsx from 'clsx';

import { openAppointletModal } from 'utils/appointlet';

import * as styles from './styles.module.scss';

function CallToAction({ size }) {
  return (
    <button
      className={clsx(styles.action, styles[`action___${size}`])}
      type="button"
      onClick={openAppointletModal}
    >
      Book a Demo
    </button>
  );
}

export default CallToAction;
